<template>
  <div class="card p-3 shadow-sm">
    <form @submit.prevent="addPreventActionDetails" class="w-100">
      <div class="row" v-if="isAllowed('accident-incident.preventive-measures.edit') && isOwnVessel()">
      <div class="col-12 text-right">
          <button class="e-btn e-btn-outlined-red ml-2" type="button" v-if="toEdit" @click="cancel">Cancel</button>
          <button class="e-btn e-btn-green ml-2" type="button" v-else @click="editValues">Edit Preventive Measures</button>
          <button class="e-btn e-btn-blue ml-2" :class="hasChanges === false ? 'e-btn-blue-disabled' : ''" type="submit" v-if="toEdit" :disabled="hasChanges === false">Save</button>
        </div>
      </div>
      <table class="w-100">
        <tr>
          <td style="width: 45%;">Detail <sub class="lead e-text-red font-weight-bold">*</sub></td>
<!--          <td v-if="toEdit">-->
<!--            <textarea v-model="preventAction.details"-->
<!--                      rows="10" class="form-control form-control-sm pre-wrap-txt"></textarea>-->
<!--          </td>-->
<!--          <td v-else>-->
<!--            <p class="pre-wrap-txt textarea-display-card mb-1">-->
<!--            {{accidentIncidentReport.preventive.details}}-->
<!--            </p>-->
<!--          </td>-->
          <td v-if="toEdit">
            <vue-editor  v-model="preventAction.details" :editor-toolbar="toolbar" ></vue-editor>
          </td>
          <td v-else>
            <div id="textArea" class="pre-wrap-txt textarea-display-card"  v-html="accidentIncidentReport.preventive.details || '' " />
          </td>
        </tr>
        <tr>
          <td>Related Equipment</td>
          <td v-if="toEdit">
            <input type="text" class="form-control form-control-sm"
                   v-model="preventAction.equipment">
          </td>
          <td v-else>{{accidentIncidentReport.preventive.equipment}}</td>
        </tr>
        <tr>
          <td>Related Procedures</td>
          <td v-if="toEdit">
            <input type="text" class="form-control form-control-sm"
                   v-model="preventAction.procedures">
          </td>
          <td v-else>{{accidentIncidentReport.preventive.procedures}}</td>
        </tr>
        <tr>
          <td>Requiring new / Revised Risk Assessment</td>
          <td v-if="toEdit">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="RiskAssessment" id="RevisedRiskAssessmentYes"
                     v-model="preventAction.for_risk_assessment"
                     value="1">
              <label class="form-check-label" for="RevisedRiskAssessmentYes">YES</label>
<!--              v-model="preventAction.for_risk_assessment"-->
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="RiskAssessment" id="RevisedRiskAssessmentNo"
                     v-model="preventAction.for_risk_assessment"
                     value="0">
              <label class="form-check-label" for="RevisedRiskAssessmentNo">NO</label>
            </div>
          </td>
          <td v-else>
            {{accidentIncidentReport.preventive.for_risk_assessment == 1 ? 'YES' : 'NO'}}
          </td>
        </tr>
        <tr>
          <td>Requiring shore training (this will prep the topics for TTOS)</td>
          <td v-if="toEdit">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="ShoreTraining" id="ShoreTrainingYes"
                     v-model="preventAction.for_shore_training"
                     :value="1">
              <label class="form-check-label" for="ShoreTrainingYes">YES</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="ShoreTraining" id="ShoreTrainingNo"
                     v-model="preventAction.for_shore_training"
                     :value="0">
              <label class="form-check-label" for="ShoreTrainingNo">NO</label>
            </div>
          </td>
          <td v-else>
            {{accidentIncidentReport.preventive.for_shore_training == 1 ? 'YES' : 'NO'}}
          </td>
        </tr>
      </table>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {permissionMixin} from "@/mixins/permissionMixins";
import {DataService} from "@/services/DataService";

const { ReportService } = require('../../../services/ReportService')
const { AlertService } = require('../../../services/AlertService')

export default {
  name: 'PreventiveAction',
  mixins:[permissionMixin],
  data () {
    return {
      preventAction: {},
      reportId: this.$route.params.id,
      toEdit: false,
      toolbar:[
        ['bold','italic','strike','underline'],
        [{'color':["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]}]
      ]
    }
  },
  methods: {
    ...mapActions(['getAccidentIncidentReport','updateAccidentIncidentPreventive']),
    async cancel() {
      if (await AlertService.cancelAlert()) {
        this.toEdit = false;
      }
    },
    async addPreventActionDetails () {
      if (await AlertService.confirmUpdateAlert()) {
        this.preventAction.id = this.reportId
        const result=await this.updateAccidentIncidentPreventive(this.preventAction)
        if(result){
          AlertService.successAlert('Updating Preventive Action Details', 'UPDATE')
          this.toEdit = false
        }
      }
    },
    async editValues () {
      this.toEdit = true
      if (this.toEdit) {
        this.preventAction = Object.assign({}, this.accidentIncidentReport.preventive)
        // console.log(this.preventAction);
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.hasChanges === true) {
      if (await AlertService.cancelChanges()) {
        next();
      } else {
        next(false);
      }

    } else {
      next();
    }
  },
  computed: {
    ...mapGetters(['accidentIncidentReport']),
    hasChanges() {
      if (this.toEdit === false) {
        return false;
      }

      let editedData = {
        details             : this.preventAction.details,
        equipment           : this.preventAction.equipment,
        procedures          : this.preventAction.procedures,
        for_risk_assessment : parseInt(this.preventAction.for_risk_assessment),
        for_shore_training  : parseInt(this.preventAction.for_shore_training),
      };

      return DataService.checkIfChanged(this.accidentIncidentReport.preventive, editedData);
    }
  }
}
</script>

<style scoped>
::v-deep .pre-wrap-txt.textarea-display-card p {
  margin-bottom: 0 !important;
}
table td {
  padding: 2px;
}
</style>
